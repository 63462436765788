<template>
  <el-container>
    <el-header style="display: flex; align-items: center">
      <div style="display: flex; justify-content: flex-start; cursor: default">
        <span @click="$router.push('/admin/videorecord/lists')">全部考试 > </span
        ><span @click="$router.push('/admin/videorecord/examlist')">{{ examName }} > </span
      ><span @click="$router.push('/admin/videorecord/monitorgroup')" style="color: #563279">{{ subjectName }} ></span>
        <span @click="$router.back()" style="color: #563279">{{ groupName }} ></span>
        <span style="color: #563279">{{ studentName }} & {{ zkzNum }}</span>
      </div>
    </el-header>
    <el-main style="padding-top: 5px">
      <div
          style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
        "
      >
        <div
            style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
        >
          {{ studentName }}&{{ zkzNum }}
        </div>
        <div style="flex: 2"></div>
      </div>
      <el-table
          :data="imagesVideoList"
          stripe
          style="width: 100%; margin-top: 10px; overflow: auto"
      >
        <el-table-column
            label="全选"
            type="selection"
            width="55"
            align="center"
        >
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <img
                src="@/assets/image/file.png"
                @click="gotoImagesVideoList(scope.row.type, scope.row.typeName)"
            />
            <span style="margin-left: 10px">{{ scope.row.typeName }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>

export default {
  data() {
    return {
      imagesVideoList: [
        {
          type: 1,
          typeName: "AI预警截图"
        },
        {
          type: 2,
          typeName: "正面监控视频"
        },
        {
          type: 3,
          typeName: "背面监控视频"
        }
      ],
      examName: "",
      subjectName: "",
      groupName: "",
      zkzNum: "",
      studentName: ""
    };
  },
  created() {
    this.examName = window.localStorage.getItem("examName")
    this.subjectName = window.localStorage.getItem("subjectName")
    this.groupName = window.localStorage.getItem("groupName")
    this.zkzNum = window.localStorage.getItem("zkzNum")
    this.studentName = window.localStorage.getItem("studentName")
  },
  methods: {
    gotoImagesVideoList(type, typeName) {
      window.localStorage.setItem("type", type)
      window.localStorage.setItem("typeName", typeName)
      // 判断type 1,2,3
      if (type == 1) {
        this.$router.push({
          name: 'images-list'
        });
      }
      if (type == 2) {
        this.$router.push({
          name: 'video-front'
        });
      }
      if (type == 3) {
        this.$router.push({
          name: 'video-back'
        });
      }
    },
  },
};
</script>

<style scoped>
/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}

/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}

::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}

::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>